import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Header from '../components/header/header'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageDetailedHeader from '../components/shared/pageDetailedHeader'
import { Element } from 'react-scroll'
import ToolsNav from '../components/tools/toolsNav'
import styled from 'styled-components'
import Img from 'gatsby-image'

const ToolsPageContainerWrapper = styled.div`
  background-image: linear-gradient(-180deg, #f3f3f3 5%, #ddd 100%);
  padding: 60px 20px 150px 20px;
  @media (max-width: 1024px) {
    padding: 60px 20px 150px 0;
  }
  .page-inner {
    color: rgba(0, 0, 0, 0.9);
    h2 {
      font-weight: 600;
      position: sticky;
      top: 0;
      background: #425b83;
      color: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      padding: 20px;
      z-index: 2;
      margin-bottom: 30px !important;
    }
  }
  @media (max-width: 900px) {
    padding: 0 30px 150px 0;
    .page-inner {
      h2 {
        background: #223960;
        border-bottom: 1px solid #425b83;
        margin: 0 -30px 30px -30px;
        border-radius: 0;
      }
    }
  }
`
const ToolsPageContainerInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 60px;
  @media (max-width: 1024px) {
    grid-gap: 60px;
    grid-template-columns: 180px 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    margin-left: 30px;
  }
`
const ToolsWrapper = styled.div``

const ToolsPage = () => {
  const data = useStaticQuery(graphql`
    {
      headerBG: file(relativePath: { eq: "page-header-tools.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      designTools: allWordpressWpTools(
        filter: {
          acf: { expertise_field: { elemMatch: { slug: { eq: "design" } } } }
        }
        sort: { fields: menu_order, order: ASC }
      ) {
        nodes {
          ...toolsFields
        }
      }
      productivityTools: allWordpressWpTools(
        filter: {
          acf: {
            expertise_field: { elemMatch: { slug: { eq: "productivity" } } }
          }
        }
        sort: { fields: menu_order, order: ASC }
      ) {
        nodes {
          ...toolsFields
        }
      }
      remoteTools: allWordpressWpTools(
        filter: {
          acf: { expertise_field: { elemMatch: { slug: { eq: "remote" } } } }
        }
        sort: { fields: menu_order, order: ASC }
      ) {
        nodes {
          ...toolsFields
        }
      }
      devTools: allWordpressWpTools(
        filter: {
          acf: {
            expertise_field: { elemMatch: { slug: { eq: "development" } } }
          }
        }
        sort: { fields: menu_order, order: ASC }
      ) {
        nodes {
          ...toolsFields
        }
      }
    }

    fragment toolsFields on wordpress__wp_tools {
      id
      title
      acf {
        cta_label
        expertise_field {
          name
        }
        image_bg_color
        link
        overview
        top_feature
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const {
    headerBG,
    designTools,
    productivityTools,
    remoteTools,
    devTools,
  } = data

  return (
    <Layout lightMode={true}>
      <SEO
        title="Tools"
        keywords={[
          `productivity`,
          `tools`,
          `business processes`,
          `design`,
          `designer tools`,
          `developer`,
          `developer tools`,
          `brainstorming tools`,
          `react`,
        ]}
      />
      <Header colour="#FF8862" />
      <PageDetailedHeader
        bg={headerBG}
        intro="Over the years I've learned that the right tools at the right time can amplify your offering and save you valuable time. Here's a collection of the tools and services I've come to love in the field of design, development and overall business processes."
      />
      <ToolsPageContainerWrapper>
        <ToolsPageContainerInner>
          <ToolsNav />
          <div className="page-inner">
            <Element name="dev">
              <h2>Development &amp; Delivery Tools</h2>
              <ToolsWrapper>
                {devTools.nodes.map(tool => (
                  <ToolCard tool={tool} key={tool.id} />
                ))}
              </ToolsWrapper>
            </Element>
            <Element name="design">
              <h2>Design Tools</h2>
              <ToolsWrapper>
                {designTools.nodes.map(tool => (
                  <ToolCard tool={tool} key={tool.id} />
                ))}
              </ToolsWrapper>
            </Element>
            <Element name="productivity">
              <h2>Productivity Tools</h2>
              <ToolsWrapper>
                {productivityTools.nodes.map(tool => (
                  <ToolCard tool={tool} key={tool.id} />
                ))}
              </ToolsWrapper>
            </Element>
            <Element name="remote">
              <h2>Remote Working Tools</h2>
              <ToolsWrapper>
                {remoteTools.nodes.map(tool => (
                  <ToolCard tool={tool} key={tool.id} />
                ))}
              </ToolsWrapper>
            </Element>
          </div>
        </ToolsPageContainerInner>
      </ToolsPageContainerWrapper>
    </Layout>
  )
}
export default ToolsPage

///////////////////////////////////////////////////////////////////////////////
const ToolCard = ({ tool }) => (
  <ToolCardWrapper
    bg={tool.acf.image_bg_color}
    href={tool.acf.link}
    rel="noopener nofollow"
    target="_blank"
  >
    <div className="image">
      <Img fluid={tool.featured_media.localFile.childImageSharp.fluid} alt="" />
    </div>
    <div className="content">
      <div className="header">
        <h3 dangerouslySetInnerHTML={{ __html: tool.title }} />
        <div className="label">{tool.acf.top_feature}</div>
      </div>
      <div className="body">
        <p>{tool.acf.overview}</p>
      </div>
      <div className="footer">
        {tool.acf.cta_label}{' '}
        <svg
          width="36px"
          height="19px"
          viewBox="0 0 36 19"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>icon-readmore-arrow</title>
          <g
            id="BtnMoreArrow"
            transform="translate(-779.000000, -1308.000000)"
            stroke="#3E2E5C"
            strokeWidth="1.5"
          >
            <g
              id="ArticleItem-Copy"
              transform="translate(100.000000, 962.000000)"
            >
              <g id="Btn-More" transform="translate(544.000000, 330.000000)">
                <g id="Group" transform="translate(21.000000, 13.000000)">
                  <g
                    id="icon-readmore-arrow"
                    transform="translate(115.000000, 3.000000)"
                  >
                    <path d="M6.5,9.5 L33.3495708,9.5" id="lineLong" />
                    <path
                      d="M24.7146068,0.918189674 L33.5,9.5"
                      id="lineDiagonalTop"
                    />
                    <path
                      d="M24.8333333,9.74957911 L33.5,18.5"
                      id="lineDiagonalBottom"
                      transform="translate(29.000000, 14.000000) scale(1, -1) translate(-29.000000, -14.000000) "
                    />
                    <path d="M2.09090909,9.5 L0.522727273,9.5" id="lineShort" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </ToolCardWrapper>
)
const ToolCardWrapper = styled.a`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.119127);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 300px 1fr;
  overflow: hidden;
  margin-bottom: 30px;
  .image {
    border-right: 1px solid #eee;
    background-color: ${({ bg }) => bg};
  }
  .body {
    padding: 30px;
    p {
      margin: 0;
    }
  }
  .header {
    padding: 20px 30px;
    h3 {
      font-weight: 600;
      margin: 5px 0 0 0;
      line-height: 1.2;
    }
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 90px auto 70px;
  }
  .footer {
    border-top: 1px solid #eaeaea;
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    font-weight: 600;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 25px;
    justify-content: flex-end;
    align-items: center;
    transition: all 200ms ease-in-out;
  }
  .label {
    color: #818181;
    background-color: #d8d8d8;
    padding: 6px 10px;
    border-radius: 6px;
  }
  transition: all 200ms ease-in-out;
  &:hover {
    cursor: pointer;
    transform: translateY(-8px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    .footer {
      grid-gap: 15px;
    }
  }
  @media (max-width: 900px) {
    grid-template-columns: 250px 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .header {
      border-top: 1px solid #eaeaea;
    }
  }
  @media (max-width: 600px) {
    .header {
      grid-template-columns: 1fr;
      justify-items: flex-start;
    }
    .content {
      grid-template-rows: auto auto 70px;
    }
  }
`
