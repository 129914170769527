import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

const NavWrapper = styled.nav`
  span {
    display: inline-block;
  }
  img {
    display: none;
  }
  text-transform: uppercase;
  text-align: right;
  position: sticky;
  top: 100px;
  display: table;
  a {
    display: block;
    padding: 10px 40px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    &[aria-current='page'],
    &.active {
      background-color: #425b83;
      border-right: 3px solid #223960;
      border-radius: 4px 0 0 4px;
      color: #fff;
      font-weight: 700 !important;
      &:hover {
        border-right: 3px solid #425b83 !important;
        color: #fff !important;
      }
    }
    &:hover {
      color: rgba(0, 0, 0, 0.7);
      border-right: 3px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
  @media (max-width: 900px) {
    display: none;
  }
`

const ToolsNav = () => {
  const [scrollOffset, setScrollOffset] = useState()
  useEffect(() => {
    if (window.innerWidth < 768) {
      setScrollOffset(-10)
    } else {
      setScrollOffset(-90)
    }
  }, [])

  return (
    <NavWrapper>
      <Link
        to="dev"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <span>Development &amp; Delivery</span>
      </Link>
      <Link
        to="design"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <span>Design</span>
      </Link>
      <Link
        to="productivity"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <span>Productivity</span>
      </Link>
      <Link
        to="remote"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <span>Remote Work</span>
      </Link>
    </NavWrapper>
  )
}
export default ToolsNav
